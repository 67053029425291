import React from "react";
import "./Footer.css";
import { Fade } from "react-reveal";
import emoji from "react-easy-emoji";

export default function Footer() {
  return (
    <Fade bottom duration={1000} distance="5px">
    <div className="footer-div">
      <p className="footer-text">{emoji("01011001 01101111 01110101 00100111 01110010 01100101 00100000 01100001 00100000 01100011 01110101 01110010 01101001 01101111 01110101 01110011 00100000 01101111 01101110 01100101 00101100 00100000 01001001 00100000 01101100 01101001 01101011 01100101 00100000 01111001 01101111 01110101 00101110 00100000 00101101 01001101 01101001 01101011 01100101")}</p>
    </div>
    </Fade>
  );
}
